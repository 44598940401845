<template>
   
      

    <div >
       
      <!--   <base-navbar/> -->
        <!-- ======= Hero Section ======= -->
       
        <section  style="background-image:url('/img/bg-2.jpeg'); background-repeat: no-repeat; background-position: center;background-attachment: fixed;" id="hero" class="d-flex align-items-center">

            <div class="container">
            <div class="row">
                <div class="col-lg-6 pt-2 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                <h1 class="text-white">Welcome to <img src="/img/bcart-white.png" height="40px" alt="" srcset=""></h1>
                <ul class="text-white">
                    <li><i class="ri-check-line text-white"></i>Have easy access to analytics on sales</li>
                    <li><i class="ri-check-line text-white"></i> Free Inventory management system to manage your photos</li>
                    <li><i class="ri-check-line text-white"></i>Generate general inventory report</li>
                </ul>
                <div class="mt-3">
                    
                    <a v-if="!authUser" @click.prevent="route('/auth/login')" class="btn-get-quote"><span style="color: white">Login</span></a>
                    <a v-if="authUser && authUser.user" @click.prevent="route('/panel/dashboard')" class="btn-get-quote"><span style="color: white">Go to Dashboard</span></a>
                </div>
                </div>
                <!-- <div class="col-lg-6 order-1 order-lg-2 hero-img">
                <img src="img/bg-1.jpeg" class="img-fluid" alt="">
                </div> -->
            </div>
            </div>

        </section><!-- End Hero -->

     
    
    </div>
   
    
    
</template>
<script>
import {mapState} from 'vuex'
    export default{
        name:'welcome',
        data(){
            return{
                email:'',
                username:''
            }
        },
        computed:{
            ...mapState({
                authUser: (state) => state.authStore.authUser
            })
        },
        methods:{
            route(path){
                this.$router.push({path})
            },
        },
        created() {
        /*  if((this.authUser && this.authUser.status == 1) && (this.authUser.role == "Admin")) {
                this.$router.push({path: '/admin/dashboard'})

            }else {
                
                this.$router.push({path: '/auth/login'})
            } */
        }
        
    }
</script>
